<template>
    <div class="insurance-client__menu__filters__blok container pl-0" style="margin-bottom: 30px;">
      <div class="insurance-client__menu__filters">
        <div class="row align-items-center">
          <div class="col-auto pr-0">
            <button @click="filterStatusWrap()"style="width: 120px;text-align: left">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="4" width="24" height="2" fill="#24282C"/>
                <rect y="18" width="24" height="2" fill="#24282C"/>
                <circle cx="8" cy="5" r="4" fill="white" stroke="#24282C" stroke-width="2"/>
                <circle cx="16" cy="19" r="4" fill="white" stroke="#24282C" stroke-width="2"/>
              </svg>
              {{ condition.isShowFilter ? 'Приховати' : 'Фільтри'}}
            </button>
          </div>
          <div class="col-auto px-0 ml-2">
            <div class="icon">
              <button @click="getXls()" class="btn" style="width: 181px">
              <button v-if="! isLoadData && (clients && clients.length > 0)">
                <div v-if="isLoadXml"
                     class="option-loader spinner-border"
                     style="height: 22px; width: 22px; margin-right: 12px;"
                     role="status">
                </div>
                <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="24" height="24" rx="3" fill="#207245"/>
                  <path d="M6.16667 4.5C5.24167 4.5 4.5 5.24167 4.5 6.16667V17.8333C4.5 18.7583 5.24167 19.5 6.16667 19.5H17.8333C18.7583 19.5 19.5 18.7583 19.5 17.8333V6.16667C19.5 5.24167 18.7583 4.5 17.8333 4.5H6.16667ZM6.16667 6.16667H17.8333V17.8333H6.16667V6.16667ZM12.8333 12L15.5 16.1667H13.8333L12 13L10.1667 16.1667H8.5L11.1667 12L8.5 7.83333H10.1667L12 11L13.8333 7.83333H15.5L12.8333 12Z" fill="white"/>
                </svg>
                Експорт в Excel
              </button>
            </button>
            </div>
          </div>
          <div class="col-auto pl-0 fast-search-bar">
            <div class="input-group">
              <input class="form-control" placeholder="пошук" v-model="fastSearch" @keydown.enter="handleInput"/>
              <div class="input-group-append">
                <button class="btn" type="button" @click="handleInput"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.2939 12.5786H13.3905L13.0703 12.2699C14.191 10.9663 14.8656 9.27387 14.8656 7.43282C14.8656 3.32762 11.538 0 7.43282 0C3.32762 0 0 3.32762 0 7.43282C0 11.538 3.32762 14.8656 7.43282 14.8656C9.27387 14.8656 10.9663 14.191 12.2699 13.0703L12.5786 13.3905V14.2939L18.2962 20L20 18.2962L14.2939 12.5786ZM7.43282 12.5786C4.58548 12.5786 2.28702 10.2802 2.28702 7.43282C2.28702 4.58548 4.58548 2.28702 7.43282 2.28702C10.2802 2.28702 12.5786 4.58548 12.5786 7.43282C12.5786 10.2802 10.2802 12.5786 7.43282 12.5786Z" fill="#999DA6"/>
                </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="col-auto  ml-auto d-none d-md-block">
            <!-- Пропуск или пустое место -->
          </div>
          <div class="col-auto ml-auto right-side">
            <div class="row">
              <div class="col-auto ml-auto custom__item">
                <div class="icon">
                  <button @click="uploadClientBase()">
                    <div v-if="isUploadingClientBase"
                         class="option-loader spinner-border"
                         style="height: 22px; width: 22px; margin-right: 12px;"
                         role="status">
                    </div>
                    <svg class="keep-fill" width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <rect width="30" height="28.3" fill="url(#pattern1)"/>
                      <defs>
                        <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
                          <use xlink:href="#image0_89_6" transform="scale(0.0111111)"/>
                        </pattern>
                        <image id="image0_89_6" width="90" height="90" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAAECklEQVR4nO2d3YtVZRSHH5vKpIuo0ZqhC5Ug+hccgkgmygsxER3zRoIoibwRzekPyC8IKWoQQbzI7gNBEIlExxi1Dwr7gLoIMQWVAh1tjtZZ8h7WiJ3OPnuf8Zz9rv2e9cAP5mZm1vrNOu9+P9a7BxzHcRzHcRzHcRzHcRzHcZwKMwSsAnYAx4AfgAvATeAW8CfwK3AU+BB4Tb/HKcBjwBvACaAOyBx0BtgMDBb5hf3GE8AerVbpkqaBvcDTsZOzwIPANuCvLhrcyvB3gYfoUxYDkz00uFnfAc/SZ6zscRVn6Rqwmj5hvc4aJJL+ATaROK9romJAb5Ioy4HbBgy+t7JfJcEH32UD5jbrOvAcifAAcNqAqVn6NpWp3yYDZuZpKwYJf/0XdSV3EvhNFwVBvwDHgV3ACPAkcNWAkUWGkGGM8CiwBbjUQQI1AyYW1QcY4JUODZYKajrmRlR4kO28j500qZjeiWHyPGDCQPJSosLsqHTeN5C4lKy6PsBL44U+Gi6kSWNlmbxAj4ikT7W3LKPfNpCsRNSRsh6APxlIViIqfJp7zjIDiUpkhZVsz9luIFGJrJkyjP48YnLbtS9jSL+eiRRL2Dbo2epvFDgcaUpX0zPEZl4G/o5kdjg0XgsMdMvkEe0AkoiVvKJNfCsiVraoN+G5NWceBj4G/jVsshWzg0cfqWcd8TjwZcTApc1wkUXMYWRWp4CFRQMeNDBPnilYydYqO+ictq/lnox8UbFKtljZoQFzPm2YqGglW6zsT8jg+ci7cbX7rGRrlV3XGdv/OjbPJVDJ1ir7++Z59pqIwYiu8tqRN49ux3jk3P7T6RR7KjdUYAjIIm/IeSpybuHKR4MlBk5Khgt89LPIG3qGI+dW13a3RvemRNZ4jsl5Rrcz+z0D+W0MgRw0EEhNzR5Wjbd4iOUZPWt288+x0KBzIAT6tYFApICKGG1VZ0Og5w0EIokb/Ttdvk7mRtPSgxsYGcMk8YpuHH1VoWVWKm50uMnQuFsXOxBJ3Ogw4eAzA4FI4kZ/WqWuoyykAnorBPqMgSW4JGx0Xbc5GnxlICBJ1Ohwh+cuGwwEJIkave7eYAf0tlTsoCQxo39s1WDzkoHAJDGjw5Xrluw3EJwkYnQ46G7byX/WQJBScaOngEfIYZHR8ToLMaafO+lWWqRv0oodtFTM6Cn1riPCMLLPQPBSEaMnigwX7RjVj0PsRLKwMIXLnF10yoC+BXEy4nI9ixix1HXFN6ZN+j1hqW6SHAK+0f3sWsJG14ArutV5SHO/u3eRItLhH8CZI250SYhXtBudFOIV7UYnhXhFu9FJIV7RbnRSiFe0G50UF1tUdPifK06X2d3C6PAGSafLzFez/1CFt/V2/NqGWNwBb0m9BJjicF4AAAAASUVORK5CYII="/>
                      </defs>
                    </svg>
                  </button>
                </div>
                <div class="label">
                  Скоро
                </div>
                <i class="icon question" data-html="true" data-placement="bottom" data-select="true" data-toggle="tooltip" title="Кнопка для завантаження клієнтів до списку."></i>
              </div>
              <div class="col-auto custom__item">
                <div class="icon">
                  <button @click="createClient()">
                    <div v-if="isCreatingClient"
                         class="option-loader spinner-border"
                         style="height: 22px; width: 22px; margin-right: 12px;"
                         role="status">
                    </div>
                    <svg class="keep-fill" width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_d_11_20)">
                        <rect x="4" y="1.79999" width="30" height="28.2" rx="3" fill="#FFAD3D"/>
                        <rect x="4" width="30" height="28.2" rx="3" fill="#FFAD3D"/>
                      </g>
                      <rect x="18.3999" y="9" width="1.2" height="12" fill="white"/>
                      <rect x="25" y="14.4" width="1.2" height="12" transform="rotate(90 25 14.4)" fill="white"/>
                      <defs>
                        <filter id="filter0_d_11_20" x="0" y="0" width="38" height="38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                          <feOffset dy="4"/>
                          <feGaussianBlur stdDeviation="2"/>
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11_20"/>
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_11_20" result="shape"/>
                        </filter>
                      </defs>
                    </svg>

                  </button>
                </div>
                <div class="label">
                  Скоро
                </div>
                <i class="icon question" data-html="true" data-placement="bottom" data-select="true" data-toggle="tooltip" title="Кнопка для створення клієнта."></i>
              </div>
            </div>
          </div>

        </div>
      </div>
        <transition name="fade">
            <div v-show="condition.isShowFilter" class="menu filters mt-4">
            <div class="row">
                <div class="col-sm-4 form-group">
                    <div class="filter-title">
                        Дата створення
                    </div>
                    <el-date-picker
                        class="form-control-el w-100"
                        v-model="dateFilter"
                        type="daterange"
                        align="right"
                        format="dd-MM-yyyy"
                        start-placeholder="Від"
                        :picker-options="pickerOptions"
                        end-placeholder="До"
                        @change="refreshDate"
                    />
                </div>
              <div class="col-sm-4 form-group">
                <div class="filter-title">ПІБ клієнта</div>
                <div>
                  <input class="form-control" v-model="listQuery.clientFio"/>
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-sm-3 form-group">
                    <button @click="getList()" class="btn pl-0 w-100 btn-primary">
                        Застосувати
                    </button>
                </div>
            </div>
        </div>
        </transition>
    </div>
</template>

<script>
    import { userMixin, partnerMixin, companyMixin, orderMixin } from '@/mixin'
    //import {list as listCompanies} from "@/api/company";
    export default {
        mixins: [userMixin, partnerMixin, companyMixin, orderMixin],
        name: 'FilterBlock',
        props: ['isLoadXml', 'isLoadData', 'clients'],
        data: function () {
            return {
                isCreatingClient:false,
                isUploadingClientBase:false,
                dateFilter: new Date(),
                condition: {
                    isShowBlock: false,
                    isShowFilter: false,
                    isShowChildrenUser:false
                },
                fastSearch: null,
                listQuery: {
                    clientFio: null,
                    toDate: null,//moment().calendar(),
                    fromDate: null,//moment().subtract(2, 'days').calendar(),
                },
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    },
                },
            }
        },
        created() {
            /*
            //Установка начальной и конечной даты
            const oneWeekAgo = new Date();
            oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
            oneWeekAgo.setHours(0, 0, 0, 0);
            this.dateFilter = [oneWeekAgo, moment()];

            this.listQuery.fromDate = moment(this.dateFilter[0]).format();
            this.listQuery.toDate =  moment(this.dateFilter[1]).format();*/
        },
        mounted: function () {

        },
        watch:{
        },
        computed: {
            partnersList() {
                return this.$store.getters.ALL_PARTNERS;
            },
            orderStatuses() {
                return this.$store.getters.ORDER_STATUSES;
            },
            orderTypesList() {
                return this.$store.getters.ALL_ORDER_TYPES;
            },
        },
        methods: {
            handleInput() {
                this.$emit('init-clients-fast-list');
            },
            uploadClientBase(){
              this.isUploadingClientBase = true;
              this.$alert("Нажаль тут поки що не працює :(");
              this.isUploadingClientBase = false;
            },
            createClient(){
              this.isCreatingClient = true;
              this.$alert("Нажаль тут поки що не працює :(");
              this.isCreatingClient = false;
            },
            getQuery() {
                this.listQuery.fromDate = moment(this.dateFilter[0]).format();
                this.listQuery.toDate =  moment(this.dateFilter[1]).format();

                return this.listQuery;
            },
            getFastQuery() {
                return this.fastSearch;
            },
            refreshDate: function() {
                this.listQuery.fromDate = moment(this.dateFilter[0]).format();
                this.listQuery.toDate =  moment(this.dateFilter[1]).format();
            },
            filterStatusWrap: function(scroll = false) {
                this.condition.isShowFilter = ! this.condition.isShowFilter;

                if (scroll) {
                    let filter = this.$el.querySelector(".insurance-client__menu__filters");
                    filter.scrollTop = filter.scrollHeight;
                }
            },
            getXls : function() {
                return this.$emit('download-xls');
            },
            getList() {
                this.condition.isShowFilter = false;
                return this.$emit('init-clients-list');
            },
            initClientList: function() {
                return this.$emit('init-clients-list');
            }
        }
    }
</script>

<style scoped>
.insurance-client__menu__filters{
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
  }
  .fast-search{
    width: 220px;
  }
  .input-group input.form-control{
    border: 1px solid #D9D9D9;
    border-radius: 3px!important;
    padding-right: 32px;
  }
  .input-group input.form-control:focus{
    border-color: #FFAD3D;
  }
  .input-group .input-group-append{
    position: absolute;
    right: 0;
    top: 6px;
    border: none;
    z-index: 3;
  }

  .custom__item{
    padding: 18px;
    border-radius: 3px;
    text-decoration: none;
    cursor: pointer;
    display: block;
  }
  .custom__item .label{
    position: absolute;
    right: 1px;
    top: 1px;
    z-index: 2;
    color: #EF4137;
    text-transform: uppercase;
    text-align: center;
    font-size: 10px;
    line-height: normal;
    font-weight: bold;
    height: 15px;
    padding: 0 4px;
    border: 1px solid #EF4137;
    border-radius: 3px;
  }
  .insurance-client__menu__filters .right-side .icon button{
    width: 40px;
    height: 30px;
  }
}

@media screen and (max-width: 837px){
  .insurance-client__menu__filters{
    .right-side{
      margin-left: 0!important;
      margin-top: 5px;
    }
  }
}
@media screen and (max-width: 818px){
  .insurance-client__menu__filters{
    .d-none{
      display: none!important;
    }
  }
}
@media screen and (max-width: 605px){
  .insurance-client__menu__filters{
    .fast-search-bar{
      padding-left: 15px!important;
      margin-top: 13px;
    }
  }
}
@media screen and (max-width: 498px){
  .insurance-client__menu__filters{
    .align-items-center{
      .col-auto{
        margin: 0 auto!important;
        margin-bottom: 13px!important;
      }
    }
  }
  .container.insurance-client__menu__filters__blok{
    padding: 0!important;
  }
}

</style>
